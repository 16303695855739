<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Staff Identification
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['update:staff']">
        <button
          @click="updateStaffFields"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Details</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap -my-3 -mx-5">
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">National Insurance Number</label>
        <input v-model="staff.ni" class="bge-input bge-input-spacing rounded" name="manufacturer" type="text" :disabled="isReadOnly" />
      </div>
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">Passport Number</label>
        <input v-model="staff.passport" class="bge-input bge-input-spacing rounded" name="manufacturer" type="text" :disabled="isReadOnly" />
      </div>
      <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
        <label class="uppercase font-semibold text-sm">Driving Licence Number</label>
        <input v-model="staff.driving_license" class="bge-input bge-input-spacing rounded" name="manufacturer" type="text" :disabled="isReadOnly" />
      </div>
    </div>
    <!-- Vehicle Information -->

    <!-- /Registration Details -->
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { COUNTRIES } from "@/utils/constants";
import Notification from "@/components/Notification.vue";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
import auth from "@/utils/auth";

export default {
  name: "Information",
  components: {
    Datepicker,
    Notification,
    IsAuthorized,
  },
  props: {
    staff: Object,
  },
  data() {
    return {
      countries: [],
      isReadOnly: false,
    };
  },
  mounted() {
    this.countries = COUNTRIES;
    this.isReadOnly = !auth(["secret:staff"]);
  },
  methods: {
    async updateStaffFields() {
      try {
        this.isBusy = true;
        await this.StaffService.updateStaff(this.staff);
        this.$emit("complete");
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Update Successful",
                  close: onClose,
                },
              },
              `Details for '${this.staff.first_name} ${this.staff.last_name}' were updated`
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "Error occurred while updating info, please try again later"
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.isBusy = false;
    },
  },
};
</script>
